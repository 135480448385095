@media (min-width: 1400px) {
  .container {
    max-width: 1100px !important;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1100px !important;
    padding: 0;
  }
}
