.btn-primary {
  text-align: center;
  padding: 10px 26px;
  gap: 10px;
  background: var(--color-secondary);
  color: var(--background-color);
  border-radius: 20px;
  border: none;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.2px;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &:hover {
    color: white;
    background: #585d63 !important;
  }
}
.cta-btn {
  display: flex !important;
  min-width: 178px;
  height: 45px;
  padding: 9px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #410099 !important;
  color: #fafafa !important;
  text-align: center;
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-decoration: none;
  transition: 0.2s;
  border: none;
}

.cta-btn:hover {
  color: black !important;
  background: #fff !important;
  border: 1px solid black !important;
}
.cta-yl-btn {
  display: flex !important;
  min-width: 178px;
  height: 45px;
  padding: 9px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ffa400 !important;
  color: black !important;
  text-align: center;
  font-family: var(--font-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-decoration: none;
  transition: 0.2s;
  border: none;
}

.cta-yl-btn:hover {
  background: transparent !important;
  border: 1px solid #101820 !important;
}

.hidden {
  display: none !important;
}
button:disabled {
  color: white !important;
  background: #b7babc !important;
  border-color: transparent !important;
}
.btn-disabled {
  color: white;
  background: #b7babc !important;
  text-align: center;
  padding: 10px 26px;
  gap: 10px;
  border-radius: 20px;
  border: none;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.05em;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-secondary {
  text-align: center;
  padding: 10px 26px;
  gap: 10px;
  background: #d9d9d9;
  color: var(--color-secondary);
  border-radius: 10px;
  border: none;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.05em;
  transition: 0.2s;
  cursor: pointer;
}

.btn-secondary-outline {
  text-align: center;
  padding: 10px 26px;
  gap: 10px;
  background: transparent;
  color: #000;
  border-radius: 20px;
  border: 1px solid #000;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.2px;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &:hover {
    background: #410099 !important;
    color: #fafafa !important;
  }
}

.btn-secondary-outline:hover {
  color: black;
}

.free-month-alert-con {
  height: 28px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-secondary);
  letter-spacing: 0.05em;
  display: none;
  justify-content: center;
  align-items: center;
  background: #f3edf7;
}

.free-month-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  background: transparent;
  border: none;
}

.free-month-alert:hover {
  color: black;
}

.free-month-alert span {
  color: var(--color-primary);
  font-weight: 600;
  margin: 0 5px;
}

.error {
  color: red !important;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}
.success_msg {
  color: green !important;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}
.mobile-header-top {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 2;
  height: 56px;
  display: none;
  justify-content: space-between !important;
  align-items: center;
  background: #101820;
  padding: 8px 16px;
  .mobile-menu-icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a5aaad;
    border-radius: 50%;
    background: transparent;
    color: white;
  }
  .mobile-header-right {
    display: flex;
    align-items: center;
    .qr-icon {
      margin-top: 5px;
      margin-right: 25px;
      background: transparent;
      border: none;
      padding: 0;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .mobile-header-profile {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: transparent;
    border: none;
    color: white;
  }
  .showSidebar {
    display: flex !important;
  }
}
.remove_shadow {
  box-shadow: unset !important;
}
@media (max-width: 576px) {
  .mobile-header-top {
    display: flex !important;
  }
}

.show_menu_btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 5%;
  top: 23px;
  padding: 0;
  img {
    width: 27px;
    height: 18px;
  }
}
.pt-60 {
  padding-top: 60px;
}

.img_animation {
  &::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3)
    );
    left: -10%;
    z-index: 2;
    transform: skew(-10deg);
    overflow: hidden;
  }
  &:hover::after {
    animation: light 1.5s;
  }
}
.d-none {
  display: none;
}
.text_under {
  text-decoration: underline;
}
.text_under_leapon {
  text-decoration: underline;
  color: #410099 !important;
  font-size: unset !important;
}
.leapon_color{
  background: linear-gradient(90deg, #410099 22.54%, #afa5ff 88.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes light {
  0% {
    left: -10%;
  }
  100% {
    left: 175%;
  }
}
